import { useState, useEffect, lazy, Suspense } from 'react';

const Select = lazy(() => import('./Select'));
const AmountInput = lazy(() => import('./AmountInput'));
const Loader = () => <div>Loading calculator...</div>;

const formatMoney = (value) => {
  const moneyValue = Number(value) || 0;

  // Use the US english default format
  return new Intl.NumberFormat().format(moneyValue);
};

// keep track of the processor values
const allowedProcessors = {
  paystack: 'paystack',
  flutterwave: 'flutterwave',
  'bank transfer': 'ufitpay',
};

async function getEstimate(amount, processor, setEstimate) {
  if (!processor || !amount) return;

  // make api call here
  try {
    const estimateValue = await fetch(
      'https://clink-api.herokuapp.com/v1/estimate',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          processor: allowedProcessors[processor],
          amount: Number(amount),
        }),
      }
    ).then((res) => res.json());
    setEstimate(estimateValue);
  } catch (e) {
    console.error(e);
  }
}

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export default function App() {
  const [amount, setAmount] = useState();
  const [processor, setProcessor] = useState('paystack');
  const [estimate, setEstimate] = useState(null);
  const debouncedAmount = useDebounce(amount, 500);

  useEffect(() => {
    if (debouncedAmount) {
      getEstimate(amount, processor.toLowerCase(), setEstimate);
    } else {
      setEstimate(null);
    }
  }, [debouncedAmount, processor]);

  return (
    <Suspense fallback={<Loader />}>
      <div>
        <label className='outside-label label'>Amount paid</label>
        <div className='form-input'>
          <label htmlFor='amount'>Amount</label>
          <div className='amount-input'>
            <span>NGN</span>
            <AmountInput value={amount} setValue={setAmount} />
          </div>
        </div>
      </div>
      <div>
        <label htmlFor='amount'>SELECT A PAYMENT METHOD</label>
        <Select setValue={setProcessor} />
      </div>
      <div className={`amount-charges`}>
        <div>
          <label className='label' htmlFor='amount'>
            AMOUNT SETTLED TO YOU
          </label>
          <div className='amount'>
            NGN&nbsp;{formatMoney(estimate?.remitted) || ''}
          </div>
        </div>
        <div>
          <label className='label charges-label' htmlFor='charges'>
            CHARGES
          </label>
          <div className='amount'>
            NGN&nbsp;{formatMoney(estimate?.platFormFee) || ''}
          </div>
        </div>
      </div>
    </Suspense>
  );
}
